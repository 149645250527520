import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceString'
})
export class ReplaceStringPipe implements PipeTransform {

  transform(value: string, target: string, replaceWith: string): string {
    if (value != null) {
      let tval = new RegExp(target, "g");
      return value.replace(tval, replaceWith);
    }
    else return null;

  }

}
