import { switchMap, tap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, throwMatDialogContentAlreadyAttachedError } from '@angular/material';
import { AddressService } from './../../services/address.service';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { debounceTime, map, startWith } from 'rxjs/operators';
import { GooglemapLocation } from 'app/main/models/googlemap-location';

@Component({
    selector: 'app-address-lookup',
    templateUrl: './address-lookup.component.html',
    styleUrls: ['./address-lookup.component.scss']
})
export class AddressLookupComponent implements OnInit, OnChanges {
    filteredAddresses: Observable<string[]>;
    searchText = '';
    addressSelected = false;
    searchChanges: BehaviorSubject<string> = new BehaviorSubject<string>('');
    sessionToken: google.maps.places.AutocompleteSessionToken;
    loadingAddress: boolean = false;

    @Input() address: GooglemapLocation;
    @Output() addressChange = new EventEmitter<GooglemapLocation>();
    @Output() validateAddress = new EventEmitter<boolean>();
    @Output() inputChange=new EventEmitter();

    constructor(
        private addressService: AddressService,
        private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.initAddress();
        this.listenAddressChanges();
    }

    initAddress(): void {
        if (!this.address) {
            this.address = new GooglemapLocation({});
        }
        if (!this.sessionToken) {
            this.sessionToken = new google.maps.places.AutocompleteSessionToken();
        }
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.address && changes.address.currentValue) {
            this.patchForm(changes.address.currentValue);
        }
    }

    patchForm(address: GooglemapLocation): void {
        this.searchText = address.address ? address.address : '';
        this.searchChanges.next(this.searchText);
        this.address = address;
    }

    onSearchChange(input: string): void {
        this.searchText = input ? input : '';
        this.addressSelected = false;
        this.searchChanges.next(this.searchText);

        if (this.searchText == '') {
            let obj: GooglemapLocation = new GooglemapLocation({});
            obj.address = '';
            this.patchForm(obj);
            this.addressChange.next(obj);
        }
        this.inputChange.emit();
    }

    listenAddressChanges(): void {
        this.filteredAddresses = this.searchChanges
            .pipe(
                startWith({ address: '' }),
                debounceTime(300),
                switchMap(this.getAutocompleteAddress.bind(this)),
                map((addresses: any[]) => addresses.map(address => address.description)),
            );
    }
    getAutocompleteAddress(address: string | GooglemapLocation): Observable<any[]> {
        if (typeof address !== 'string') {
            address = address.address;
        }
        if (address && address.trim() !== '' && !this.addressSelected) {
            return this.addressService.getAutoCompleteAddress(address.trim(), this.sessionToken).pipe(
                map(result => {
                    this.cdr.detectChanges();
                    return result ? result : [];
                })
            );
        }
        return of([]);
    }
    onAddressSelect(event: MatAutocompleteSelectedEvent): void {
        const selectedAddress: string = event.option.value;
        if (!selectedAddress) { return; }
        this.addressSelected = true;
        this.sessionToken = new google.maps.places.AutocompleteSessionToken();

        this.addressService.getGeocode(selectedAddress).subscribe((result) => {
            let obj: GooglemapLocation = new GooglemapLocation({});
            obj.address = selectedAddress;
            if (result && result.length === 1) {
                const address = result[0];
                
                if (address && address.geometry && address.geometry.location) {
                    obj.lat = address.geometry.location.lat() as any;
                    obj.lng = address.geometry.location.lng() as any;
                    obj.address = selectedAddress;

                    for (var i = 0; i < address.address_components.length; i++) {
                        if (address.address_components[i].types[0] == 'country') {
                            obj.country = address.address_components[i].long_name;
                            obj.country_code = address.address_components[i].short_name;
                        }
                        else if (address.address_components[i].types[0] == 'administrative_area_level_1') {
                            obj.province = address.address_components[i].long_name;
                            obj.province_code = address.address_components[i].short_name;
                        }

                    }
                }
            }
            this.patchForm(obj);
            this.addressChange.next(obj);
            if (obj != null) {
                this.validateAddress.emit(true);
            }
        }, () => {
            let obj: GooglemapLocation = new GooglemapLocation({});
            obj.address = selectedAddress;
            this.patchForm(obj);
            this.addressChange.next(obj);
        });
    }
}