import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Constants } from './../../../constants';
import { AuthService } from './../../../services/auth.service';
import { Contact } from '../contacts/contact.model';
import { BaseService } from 'app/main/services/base.service';


@Injectable({
    providedIn: 'root'
})
export class CalendarService implements Resolve<any>
{
    basePath = Constants.basePath;
    eventPath = 'events';
    userPath = 'users';

    // contacts: Contact[];
    lastdateCreated = "";

    events: any;
    onEventsUpdated: Subject<any>;
    onEventsChanged: BehaviorSubject<any>;

    // errors emitters
    eventsErrorEmitter: BehaviorSubject<any>;
    employees: any;
    employedNormalUser = false;
    projSearchChanges: BehaviorSubject<string> = new BehaviorSubject<string>('');

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient - http service
     * @param {AuthService} _authService - auth service
     */
    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthService,
        private baseService: BaseService
    ) {
        // Set the defaults
        this.onEventsUpdated = new Subject();

        this.onEventsChanged = new BehaviorSubject([]);

        this.eventsErrorEmitter = new BehaviorSubject(null);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                // this.getUserEvents(),
                
                this.getCompanyEmployees()
            ]).then(
                () => {
                    this.eventsErrorEmitter.next('');
                    const user = this._authService.getBarereToken();
                    if (user.accountType === 'user' && user.employer != null && !user.isAdmin)
                        this.employedNormalUser = true;
                    resolve(resolve);
                },
                reject
            );
        });
    }

    /**
     * Get user events
     * @params eventId {ObjectId}
     * @returns {Promise<any>}
     */
    getUserEvents(): Promise<any> {
        const endPoint = `${this.basePath}/${this.userPath}/${this.eventPath}`;

        return new Promise((resolve, reject) => {
            this._httpClient.get(endPoint).subscribe(
                (result) => {
                    this.events = result;
                    this.onEventsChanged.next(this.events);
                    resolve(this.events);
                },
                (err) => {
                    this.eventsErrorEmitter.next(err);
                    reject();
                }
            );
        });
    }

    getUserEventsByProjects(projectIds: any, memberIds: any, cardIds: any, isMyEvent: boolean): Promise<any> {
        const endPoint = `${this.basePath}/companies/events`;

        let obj: any = {
            projectIds,
            memberIds,
            cardIds,
            isMyEvent
        }


        return new Promise((resolve, reject) => {
            this._httpClient.post(endPoint, obj).subscribe(
                (result) => {
                    this.events = result;
                    this.onEventsChanged.next(this.events);
                    resolve(this.events);
                },
                (err) => {
                    this.eventsErrorEmitter.next(err);
                    reject();
                }
            );
        });
    }


    getCompanyEmployees(): Promise<any[]> {
        const endPoint: string = this.basePath + "/users/employer/employees?isDateValidationRequire=true";
        return new Promise((resolve, reject) => {
            this._httpClient.get(endPoint).subscribe(
                (data: any) => {
                    this.employees = data.companyInfo.company.employees.map(u => u.user);
                    this.employees = this.employees.map((contact) => {
                        return new Contact(contact);
                    });
                    resolve(this.employees);
                },
                (err) => {
                    if (err.unemployed === true) {
                        resolve(null);
                    }
                }
            );
        });
    }


    /**
     * Get a event
     * @params eventId {ObjectId}
     * @returns {Promise<any>}
     */
    getEvent(eventId): Promise<any> {
        const endPoint = `${this.basePath}/${this.eventPath}/${eventId}`;

        return new Promise((resolve, reject) => {
            this._httpClient.get(endPoint, {}).subscribe(
                (result) => {

                    resolve(result);
                },
                (err) => {
                    this.eventsErrorEmitter.next(err);
                    reject();
                }
            );
        });
    }

    /**
     * Create new event
     * @params event {Event}
     * @returns {Promise<any>}
     */
    createEvent(event): Promise<any> {
        const endPoint = `${this.basePath}/${this.eventPath}`;

        return new Promise((resolve, reject) => {
            this._httpClient.post(endPoint, event).subscribe(
                (result) => {
                    this.events = result;
                    this.onEventsChanged.next(this.events);
                    resolve(this.events);
                },
                (err) => {

                    this.eventsErrorEmitter.next(err);
                    reject();
                }
            );
        });
    }

    /**
     * Update to event
     * @params eventId {ObjectId}
     * @returns {Promise<any>}
     */
    updateEvent(event): Promise<any> {
        const endPoint = `${this.basePath}/${this.eventPath}`;

        return new Promise((resolve, reject) => {
            this._httpClient.patch(endPoint, event).subscribe(
                (result) => {
                    this.events = result;
                    this.onEventsChanged.next(this.events);
                    resolve(this.events);
                },
                (err) => {
                    this.eventsErrorEmitter.next(err);
                    reject();
                }
            );
        });
    }

    /**
     * Add member to event
     * @params eventId {ObjectId}
     * @returns {Promise<any>}
     */
    addMembers(eventId): Promise<any> {
        const endPoint = `${this.basePath}/${this.eventPath}/add_member`;

        return new Promise((resolve, reject) => {
            this._httpClient.patch(endPoint, { 'eventId': eventId }).subscribe(
                (result) => {
                    this.events = result;
                    this.onEventsChanged.next(this.events);
                    resolve(this.events);
                },
                (err) => {
                    this.eventsErrorEmitter.next(err);
                    reject();
                }
            );
        });
    }

    /**
     * Remove member from event
     * @params eventId {ObjectId}
     * @returns {Promise<any>}
     */
    removeMember(eventId): Promise<any> {
        const endPoint = `${this.basePath}/${this.eventPath}/remove_member`;

        return new Promise((resolve, reject) => {
            this._httpClient.patch(endPoint, { 'eventId': eventId }).subscribe(
                (result) => {
                    this.events = result;
                    this.onEventsChanged.next(this.events);
                    resolve(this.events);
                },
                (err) => {
                    this.eventsErrorEmitter.next(err);
                    reject();
                }
            );
        });
    }

    addprojecttask(card, location, projectId): Promise<any> {
        const obj: any = { card, location, projectId };
        const endPoint = `${this.basePath}/${this.eventPath}/add-project-task`;

        return new Promise((resolve, reject) => {
            this._httpClient.post(endPoint, obj).subscribe(
                (result) => {
                    this.events = result;
                    this.onEventsChanged.next(this.events);
                    resolve(this.events);
                },
                (err) => {
                    this.eventsErrorEmitter.next(err);
                    reject();
                }
            );
        });
    }


    /**
     * Delete an event
     * @params eventId {ObjectId}
     * @returns {Promise<any>}
     */
    deleteEvent(eventId): Promise<any> {
        const endPoint = `${this.basePath}/${this.eventPath}/${eventId}`;

        return new Promise((resolve, reject) => {
            this._httpClient.delete(endPoint, {}).subscribe(
                (result) => {
                    this.events = result;
                    this.onEventsChanged.next(this.events);
                    resolve(this.events);
                },
                (err) => {
                    this.eventsErrorEmitter.next(err);
                    reject();
                }
            );
        });
    }

    /**
     * Get all users
     * @returns {Promise<any>}
     */
    getUsers(): Promise<any> {
        const endPoint = `${this.basePath}/${this.userPath}`;

        return new Promise((resolve, reject) => {
            this._httpClient.get(endPoint, {}).subscribe(
                (result) => {
                    resolve(result);
                },
                (err) => {
                    this.eventsErrorEmitter.next(err);
                    reject();
                }
            );
        });
    }

    getProjectListBatch(pageNo, myEvent: boolean, srchString: string): Observable<any> {

        let endPoint = `${this.basePath}/${this.userPath}/projects-basic-details?pageNumber=${pageNo}&pageSize=10&isMyEvent=${myEvent}&search=${srchString}`;

        return this.baseService.get(endPoint, true);
    }

    getProjectCards(data, pageNo, myEvent: boolean, srchString: string): Observable<any> {

        return this.baseService.post(`${this.basePath}/${this.userPath}/_projects-cards?pageNumber=${pageNo}&pageSize=10&isMyEvent=${myEvent}&search=${srchString}`, true, data);
    }
}
