import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef } from '@angular/core';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatRadioChange, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { LoaderComponent } from 'app/main/component/loader/loader.component';
import { Board } from 'app/main/pages/dashboard/scrumboard/board';
// import { Board } from 'app/main/pages/dashboard/projects/models/project.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProjectsService } from '../../../../main/services/projects.service';
import { CustomValidationService } from 'app/main/services/custom-validation.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss']
})
export class CreateProjectComponent implements OnInit, OnDestroy {

  frmCreateProject: FormGroup;
  templateList = [];
  validateAdd: boolean = false;
  // board: Board = new Board({});
  board: Board;
  isLoading = true;
  location: any;
  @ViewChild('errorSwal') private errorSwal: SwalComponent;
  overlayRef: OverlayRef;
  private unsubscribe = new Subject();
  @ViewChild('frmBox') mDiv: ElementRef;
  NODays = [];

  constructor(
    private projectService: ProjectsService,
    public matDialogRef: MatDialogRef<CreateProjectComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matSnackBar: MatSnackBar,
    private overlay: Overlay,
    private customValidation: CustomValidationService
  ) { }

  ngOnInit() {


    var boardVal = "custom", tempVal = "", project = "";
    // this.projectService.getTemplates().subscribe(res => {

    this.NODays = Array(30).fill(0).map((x, i) => i + 1);
    //this.templateList = this.data.templateList;
    if (this.data.templateInfo) {
      this.getTemplateList();
      boardVal = "saved";
      tempVal = this.data.templateInfo._id;
      project = this.data.templateInfo.name;
    }

    this.frmCreateProject = new FormGroup({
      projectName: new FormControl(project, [Validators.required, Validators.maxLength(100), this.customValidation.whitespaceValidator(5)]),
      address: new FormControl("", [Validators.required, Validators.pattern(/\S/)]),
      dateApproval: new FormControl("false", [Validators.required]),
      nOfDays: new FormControl(''),
      board: new FormControl(boardVal, [Validators.required]),
      templates: new FormControl(tempVal),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required)
    });
    this.frmCreateProject.controls['nOfDays'].setValue(10, { onlySelf: true });
    this.frmCreateProject.controls['startDate'].setValidators(this.customValidation.dateRangeValidator('endDate', 'startDate'));
    this.frmCreateProject.controls['endDate'].setValidators(this.customValidation.dateRangeValidator('startDate', 'startDate'));
    this.isLoading = false;
    // });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  close() {
    this.matDialogRef.close();
  }



  get formControlProject() { return this.frmCreateProject.controls; }
  onBoardChange(event: MatRadioChange) {

    if (event.value == "saved") {
      this.formControlProject.templates.setValidators([Validators.required]);
      this.formControlProject.templates.updateValueAndValidity();
      this.getTemplateList();

    }
    else {

      this.formControlProject.templates.setValidators([]);
      this.formControlProject.templates.updateValueAndValidity();
    }
  }

  getTemplateList() {
    this.projectService.getTemplates()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.templateList = res.result;
      });
  }

  addProject() {
    if (this.frmCreateProject.valid) {
      this.showHideLoader('Creating...', true);
      // this.board.name = this.frmCreateProject.get('projectName').value;
      // if (this.frmCreateProject.get('board').value == "saved")
      //   this.board.templateId = this.frmCreateProject.get('templates').value;

      // this.board={
      //   name:this.frmCreateProject.get('projectName').value,
      //   formattedAddress:this.frmCreateProject.get('address').value,
      //   location: {lat:null,lng:null}
      // }


      this.board = {
        name: this.frmCreateProject.get('projectName').value.trim(),
        formattedAddress: this.frmCreateProject.get('address').value,
        location: this.location.lat != "" ? { lat: this.location.lat, lng: this.location.lng } : { lat: null, lng: null },
        isDateChangeApproved: this.frmCreateProject.get('dateApproval').value === 'true',
        startDate: new Date(formatDate(this.frmCreateProject.get('startDate').value, "yyyy-MM-dd", 'en') + ' 00:00:00'),
        endDate: new Date(formatDate(this.frmCreateProject.get('endDate').value, "yyyy-MM-dd", 'en') + ' 23:59:59')
      }
      if (this.frmCreateProject.get('dateApproval').value === "true")
        this.board.approvalDays = this.frmCreateProject.get('nOfDays').value;

      if (this.frmCreateProject.get('board').value == "saved")
        this.board.templateId = this.frmCreateProject.get('templates').value;



      this.projectService.create(this.board)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          this.showHideLoader('', false);
          this.matSnackBar.open('Project created successfully');
          this.matDialogRef.close({ result: res });
        }, (error) => {
          this.showHideLoader('', false);
          // if (error.status == 422) {
          this.errorSwal.text = error.message || 'Something wrong!';
          this.errorSwal.show();
          // }
        });
    }
  }

  onAddressChange(event) {
    this.formControlProject.address.setValue(event.address);
  }

  showHideLoader(action, show) {
    if (show) {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
        hasBackdrop: true,
        backdropClass: 'bgdrop'
      });
      const cref = this.overlayRef.attach(new ComponentPortal(LoaderComponent));
      cref.instance.action = action;
    }
    else {
      if (this.overlayRef)
        this.overlayRef.detach();
    }
  }

}
