import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortUserName'
})
export class ShortUserNamePipe implements PipeTransform {

  transform(username: string): any {
    let user = username.trim();
    if (user != '') {
      let temp = user.split(' ');
      return (user[0] + (temp.length > 1 ? temp[temp.length - 1][0] : '')).toUpperCase();
    }

    return null;
  }

}
