import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterContentInit {

  @Input() public appAutoFocus: boolean;

  constructor(
    private element:ElementRef
  ) { }

  public ngAfterContentInit(): void {
    setTimeout(() => {
      this.element.nativeElement.focus();
    }, 100);
  }

}
