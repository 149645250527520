import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import * as XLSX from 'xlsx';
import * as filesaver from 'file-saver';
import { Subject } from 'rxjs';
import { CommunicationService } from 'app/main/services/communication.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.scss']
})
export class ExportDataComponent implements OnInit {

  selected: string = "excel";
  private _unsubscribeAll: Subject<any>;
  timesheets = [];

  data = [
    { id: 1, name: "Nivia Graffiti Basketball", brand: "Nivia", color: "Mixed", price: 391.00 },
    { id: 2, name: "Strauss Official Basketball", brand: "Strauss", color: "Orange", price: 391.00 },
    { id: 3, name: "Spalding Rebound Rubber Basketball", brand: "Spalding", color: "Brick", price: 675.00 },
    { id: 4, name: "Cosco Funtime Basket Ball, Size 6 ", brand: "Cosco", color: "Orange", price: 300.00 },
    { id: 5, name: "Nike Dominate 8P Basketball", brand: "Nike", color: "brick", price: 1295 },
    { id: 6, name: "Nivia Europa Basketball", brand: "Nivia", color: "Orange", price: 280.00 }
  ]
  constructor(
    public matDialogRef: MatDialogRef<ExportDataComponent>,
    private commService: CommunicationService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.commService.getTimesheets
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(timesheet => this.timesheets = timesheet);
  }

  ngOnDestroy(): void {

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  exportTo() {
    

    if (this.selected == "excel") {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(this.timesheets);
      XLSX.utils.book_append_sheet(wb, ws, 'Timesheets');
      const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

      let blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });      
      filesaver.saveAs(blob, 'timesheet-'+ Date.now().toString()+ '.xlsx');
    }
    else if (this.selected == "csv") {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(this.timesheets);
      const csv = XLSX.utils.sheet_to_csv(ws);
      XLSX.utils.book_append_sheet(wb, ws, 'Timesheets');
      // XLSX.writeFile(wb, 'test.csv');
      let blob = new Blob([csv], { type: 'text/plain;charset=UTF-8' });
      // let blob = new Blob([data], { type: 'text/csv' });
      filesaver.saveAs(blob, 'timesheet-'+ Date.now().toString() +'.csv');
    }

  }

  

  close() {
    this.matDialogRef.close();
  }
}
