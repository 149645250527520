import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Injectable, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DynamicOverlayService } from '../component/loader/dynamic-overlay.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderComponent } from '../component/loader/loader.component';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  private topBarButton = new BehaviorSubject(false);
  public showButton = this.topBarButton.asObservable();

  private timesheets = new BehaviorSubject([]);
  public getTimesheets = this.timesheets.asObservable();

  private onFMAccountChange = new BehaviorSubject<FileManagerAccount | null>(null);
  public fileManagerAccount = this.onFMAccountChange.asObservable();

  private onChatTourStart = new BehaviorSubject(null);
  public chatTourStarted = this.onChatTourStart.asObservable();

  private onCompanyTourStart = new BehaviorSubject(null);
  public companyTourStarted = this.onCompanyTourStart.asObservable();

  private onTimesheetTourStart = new BehaviorSubject(null);
  public timesheetTourStarted = this.onTimesheetTourStart.asObservable();

  private onProfileTourStart = new BehaviorSubject(null);
  public profileTourStarted = this.onProfileTourStart.asObservable();

  private onAuthExpiry = new BehaviorSubject(null);
  public removeOverLayComponent = this.onAuthExpiry.asObservable();

  loaderOverlayRef: OverlayRef;

  constructor(
    private dynamicOverlay: DynamicOverlayService,
    private overlay: Overlay,
  ) { }

  changeVisibility(status: boolean) {

    this.topBarButton.next(status);
  }

  changeTimesheetList(data: any) {
    this.timesheets.next(data);
  }

  updateFMAccount(data: FileManagerAccount) {
    this.onFMAccountChange.next(data);
  }

  startChatTour(data: boolean) {
    this.onChatTourStart.next(data);
  }

  startCompanyTour(data: boolean) {
    this.onCompanyTourStart.next(data);
  }

  startTimesheetTour(data: boolean) {
    this.onTimesheetTourStart.next(data);
  }

  startProfileTour(data: boolean) {
    this.onProfileTourStart.next(data);
  }

  removeOverLay(data: boolean) {
    this.onAuthExpiry.next(data);
  }

  fileSize(bytes) {
    var exp = Math.log(bytes) / Math.log(1024) | 0;
    var result = (bytes / Math.pow(1024, exp)).toFixed(2);

    return result + ' ' + (exp == 0 ? 'bytes' : 'KMGTPEZY'[exp - 1] + 'B');
  }

  getExtension(filetype) {

    if (filetype.includes('image'))
      return 'image';
    else if (filetype.includes('pdf'))
      return 'pdf';
    else if (filetype.includes('ms-excel') || filetype.includes('sheet'))
      return 'xls';
    else if (filetype.includes('msword') || filetype.includes('document'))
      return 'doc';
    else
      return 'general';
  }

  scrollToBottom(element: ElementRef) {
    try {
      element.nativeElement.scrollTop = element.nativeElement.scrollHeight;
    }
    catch (e) { }
  }

  showHideLoader(action: string, show: boolean, element: ElementRef, backdropClass?: string) {
    if (show) {
      if (element)
        this.loaderOverlayRef = this.dynamicOverlay.createWithDefaultConfig(element.nativeElement, backdropClass ? backdropClass : 'bgdrop2');
      else
        this.loaderOverlayRef = this.overlay.create({
          positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
          hasBackdrop: true,
          backdropClass: 'bgdrop'
        });
      const cref = this.loaderOverlayRef.attach(new ComponentPortal(LoaderComponent));
      cref.instance.action = action;
    }
    else {

      if (this.loaderOverlayRef) {
        this.loaderOverlayRef.detach();
      }
    }
  }

  scrollToTop() {
    const content = document.querySelector('content') as HTMLElement;
    if (content != null) {
      setTimeout(() => {
        content.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }, 1);
    }
  }

}
export interface FileManagerAccount {
  file_manager: string,
  account_name: string
}
