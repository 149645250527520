import { Component, Inject, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MatRadioChange, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectsService } from 'app/main/services/projects.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderComponent } from 'app/main/component/loader/loader.component';


@Component({
  selector: 'app-duplicate-board',
  templateUrl: './duplicate-board.component.html',
  styleUrls: ['./duplicate-board.component.scss']
})
export class DuplicateBoardComponent implements OnInit, OnDestroy {

  frmDuplicateBoard: FormGroup;
  txtProject = new FormControl({ value: this.data.name + '--DUPLICATE', disabled: true }, [Validators.required]);
  showCurrent = true;
  errMsg = "";

  private _unsubscribe = new Subject();
  overlayRef: OverlayRef;
  @ViewChild('mDiv') mDiv: ElementRef;
  constructor(
    public matDialogRef: MatDialogRef<DuplicateBoardComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private projectService: ProjectsService,
    private overlay: Overlay,
  ) { }

  ngOnInit() {
    
    // this.frmDuplicateBoard = new FormGroup({
    //   txtProject: new FormControl({value: this.data.name+'--DUPLICATE', disabled: true}, [Validators.required]),
    // txtAddress: new FormControl(""),
    // boardType: new FormControl("current", [Validators.required])
    // });
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  close() {
    this.matDialogRef.close();
  }

  get formControlProject() { return this.frmDuplicateBoard.controls; }

  onTypeChange(event: MatRadioChange) {

    if (event.value == "current") {
      this.showCurrent = true;
      this.formControlProject.txtProject.setValue(this.data.name);
      this.formControlProject.txtAddress.setValidators([]);
      this.formControlProject.txtAddress.updateValueAndValidity();
    }
    else {
      this.showCurrent = false;
      this.formControlProject.txtProject.setValue("");
      this.formControlProject.txtAddress.setValidators([Validators.required]);
      this.formControlProject.txtAddress.updateValueAndValidity();
    }
  }

  duplicateBoard() {

    // if(this.frmDuplicateBoard.valid){
    this.showHideLoader('Duplicating...', true);
    let tempdata = cloneDeep(this.data);
    let tempcards = tempdata.cards.map(({ availableLabels, ...remaining }) => remaining);


    tempcards.forEach(el => {
      let att = [];
      el.attachments.forEach(obj => att.push(obj._id));
      el.attachments = att;

      let idMemberCard = [];
      el.idMembers.forEach(obj => idMemberCard.push(obj._id));
      el.idMembers = idMemberCard;

      let timeSh = [];
      el.timesheets.forEach(obj => timeSh.push(obj._id));
      el.timesheets = timeSh;


      el.comments.forEach(comment => {

        comment.idMember = comment.idMember._id;
      });

    });


    let payload2: any = {
      name: this.txtProject.value,
      labels: tempdata.labels,
      lists: tempdata.lists.map(({ availableCards, availableProjects, checkItems, checkItemsChecked, completeCount, progress, ...remaining }) => remaining),
      cards: tempcards,
      isDateChangeApproved: tempdata.isDateChangeApproved,

    }

    if (tempdata.isDateChangeApproved)
      payload2.approvalDays = tempdata.approvalDays;

    this.projectService.duplicateBoard(payload2)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(res => {

        setTimeout(() => {
          this.showHideLoader('', false);
        }, 500);
        this.matDialogRef.close({ result: true });
      }, (err) => {
        this.showHideLoader('', false);
        err.status == 422 ?
          this.errMsg = err.message : this.errMsg = "";

      });
    // this.projectService.getProjectsForUser()
    //   .pipe(takeUntil(this._unsubscribe))
    //   .subscribe(res => {

    //     let temp = res.currentProjects.filter(el => el._id == this.data._id);

    //     let payload = {
    //       name: this.txtProject.value,
    //       labels: temp[0].labels,
    //       lists: temp[0].lists,
    //       cards: temp[0].cards
    //     };


    //     this.projectService.duplicateBoard(payload)
    //       .pipe(takeUntil(this._unsubscribe))
    //       .subscribe(res => {


    //         this.matDialogRef.close({result: true});
    //       });
    //   });
    // }
  }

  showHideLoader(action, show) {
    if (show) {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
        hasBackdrop: true,
        backdropClass: 'bgdrop'
      });
      const cref = this.overlayRef.attach(new ComponentPortal(LoaderComponent));
      cref.instance.action = action;
    }
    else {
      if (this.overlayRef)
        this.overlayRef.detach();
    }
  }
}
