import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-custom-drop-down',
    templateUrl: './custom-drop-down.component.html',
    styleUrls: ['./custom-drop-down.component.scss']
})
export class CustomDropDownComponent implements OnInit {

    @Input() items;
    @Input() displayImage = true;
    @Input() removedUser;
    @Input() property: string;
    @Input() loading: boolean = false;
    @Input() loadOnScroll: boolean = false;
    @Output() select = new EventEmitter();
    @Output() scrolledToBottom = new EventEmitter();
    @Input() loadMore: boolean = false;

    constructor() { }

    ngOnInit() {

        if (this.removedUser.length > 0) {

            // this.removedUser = this.removedUser.map(e => e[0].id);
            this.removedUser = this.removedUser.map(e => e[0][this.property]);



            this.items = this.items.map(itemData => {

                // selected
                // if (this.removedUser.indexOf(itemData.id) !== -1) {
                if (this.removedUser.indexOf(itemData[this.property]) !== -1) {
                    itemData.selected = false;
                }
                // itemData.selected = false;
                return itemData;
            });

            // this.removedUser.forEach(itemData => {

            // });
        } else {
            return this.items
        }
    }

    itemSelectionToggle = (item) => {

        this.select.next(item);
    }

    @HostListener('scroll', ['$event'])
    onScroll(event) {
        
        if (this.loadOnScroll) {
            
            var ele = event.target;

            if (Math.round(ele.scrollHeight - ele.scrollTop - ele.clientHeight) <= 3 && !this.loadMore) {
                
                this.scrolledToBottom.emit();
            }
        }
    }

}
