import { Directive, ViewContainerRef, OnInit, OnDestroy, TemplateRef, Input } from '@angular/core';
import { DecodedToken } from '../models/decoded-token';
import { AuthService } from '../services/auth.service'
import { PermissionService } from '../services/permission.service';

@Directive( {
    selector: '[hasPermission]'
} )
export class HasPermissionDirective implements OnInit, OnDestroy {

    @Input() hasPermission: string;

    constructor ( private permissionService: PermissionService, private temRef: TemplateRef<any>, private viewContainer: ViewContainerRef ) {
    }


    ngOnInit(): void {

        if ( this.permissionService.hasPermission( this.hasPermission ) ) {
            this.viewContainer.createEmbeddedView( this.temRef );
        } else {
            this.viewContainer.clear();
        }



        // if ( !token.employer ) { // Unemployed user            
        //     if ( unemployedPermissions.some( x => x == this.hasPermission ) ) {
        //         this.viewContainer.createEmbeddedView( this.temRef );
        //     } else {
        //         this.viewContainer.clear();
        //     }
        //     return;
        // }

        // if ( token.isAdmin ) { // Admin permission
        //     if ( adminPermissions.some( x => x == this.hasPermission ) ) {
        //         this.viewContainer.createEmbeddedView( this.temRef );
        //     } else {
        //         this.viewContainer.clear();
        //     }
        //     return;
        // }

        // if ( !token.isAdmin ) { // Basic permission
        //     if ( basicPermissions.some( x => x == this.hasPermission ) ) {
        //         this.viewContainer.createEmbeddedView( this.temRef );
        //     } else {
        //         this.viewContainer.clear();
        //     }
        //     return;
        // }
        // if ( token.isRootUser && token.isPaidAccount ) { // Company user > Subscription = true
        //     if ( comapnySubTruePermissions.some( x => x == this.hasPermission ) ) {
        //         this.viewContainer.createEmbeddedView( this.temRef );
        //     } else {
        //         this.viewContainer.clear();
        //     }
        //     return;
        // }

        // if ( token.isRootUser && !token.isPaidAccount ) { // Company user > Subscription = false
        //     if ( comapnySubFalsePermissions.some( x => x == this.hasPermission ) ) {
        //         this.viewContainer.createEmbeddedView( this.temRef );
        //     } else {
        //         this.viewContainer.clear();
        //     }
        //     return;
        // }
        // if ( token.isPaidAccount ) { // Subscription = true
        //     if ( comapnySubTruePermissions.some( x => x == this.hasPermission ) ) {
        //         this.viewContainer.createEmbeddedView( this.temRef );
        //     } else {
        //         this.viewContainer.clear();
        //     }
        //     return;
        // }

        // if ( token.isRootUser ) { // Company user  
        //     if ( comapnySubTruePermissions.some( x => x == this.hasPermission ) ) {
        //         this.viewContainer.createEmbeddedView( this.temRef );
        //     } else {
        //         this.viewContainer.clear();
        //     }
        //     return;
        // }




    }

    ngOnDestroy(): void {

    }
}