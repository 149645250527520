import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-mention-text-input',
  templateUrl: './mention-text-input.component.html',
  styleUrls: ['./mention-text-input.component.scss']
})
export class MentionTextInputComponent implements OnInit {

  _textInput: string = '';

  @Input() placeHolder: string = '';
  @Output() formattedComment = new EventEmitter();
  @Output() textInputChanged = new EventEmitter();
  @Input() userData: MentionUsers[] = [];
  @Input() textInput = '';
  keyNotHandled: boolean = false;
  @ViewChild('editor') editor: ElementRef;
  triggerPosition = -1;
  showList: boolean = false;
  @ViewChild('userList') private userList: ElementRef;
  @ViewChild('editorParent') private editorParent: ElementRef;
  userSearchObsv: Observable<any>;
  currentNode = {
    node: null,
    offset: -1,
    srchTxtLength: 0
  };


  constructor(
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
  }

  getCursorPosition() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const clonedRange = range.cloneRange();
    clonedRange.selectNodeContents(this.editor.nativeElement);
    clonedRange.setEnd(range.endContainer, range.endOffset);

    return clonedRange.toString().length;
  }

  convertToPlain(html) {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  checkForTrigger(e: KeyboardEvent) {
    this.keyNotHandled = false;

    const cursorPosition = this.getCursorPosition();
    const value = this.convertToPlain(this.textInput);

    if (this.triggerPosition >= 0) {

      let triggerVal = value.substring(this.triggerPosition, cursorPosition);

      if (triggerVal && triggerVal.match(new RegExp(/^[@].*$/)))
        this.showList = true;
      else
        this.showList = false;
    }
    let currentKey = e.keyCode || e.charCode;

    if (currentKey == 0 || currentKey == 229) {
      this.keyNotHandled = true;
      return;
    }

    if (e.key == '@') {
      let trigger = (value.substring(cursorPosition - 1, cursorPosition) + '@').trimLeft();
      //
      if (trigger && trigger.match(new RegExp(/^[@].*$/))) {
        this.triggerPosition = cursorPosition;
        this.showList = true;
        return;
      }
    }
    let keycode = e.keyCode || e.charCode;
    if (e.key === "Backspace" || e.key === "Delete" || keycode === 8 || keycode === 46) {

      if (this.triggerPosition == cursorPosition - 1)
        this.triggerPosition = -1;
      // if (this.triggerPosition == -1) {
      // ele.nodeValue.replace(/\u00a0/g, " ");
      let stringArr = [];
      if (keycode === 8 || e.key === "Backspace")
        stringArr = value.replace(/\u00a0/g, " ").substring(0, cursorPosition - 1).split(" ");
      else
        stringArr = value.replace(/\u00a0/g, " ").substring(0, cursorPosition).split(" ");

      let stringToVerifyForTrigger = stringArr[stringArr.length - 1].trimLeft();


      if (stringToVerifyForTrigger && stringToVerifyForTrigger.match(new RegExp(/^[@].*$/))) {
        if (keycode === 8 || e.key === "Backspace")
          this.triggerPosition = cursorPosition - stringToVerifyForTrigger.length - 1;
        else
          this.triggerPosition = cursorPosition - stringToVerifyForTrigger.length;

        this.showList = true;

        // return;
      }
      else
        this.showList = false;

    }

  }

  userSelected(user) {

    var node = this.editor.nativeElement as HTMLElement;
    setTimeout(() => {
      node.focus();
    });

    setTimeout(() => {

      // let currentEnd = (currentChildNode.textContent || currentChildNode.innerText || "").length;

      // let elementCount = node.querySelectorAll(`[data-id="${user.id}"]`).length;

      let idref = Date.now().toString();
      var range = document.createRange();
      let start = this.currentNode.offset - this.currentNode.srchTxtLength;
      let end = start + this.currentNode.srchTxtLength;

      // range.setStart(this.currentNode.node, this.currentNode.offset - 1);
      // range.setStart(this.currentNode.node, currentChildNode.textContent.lastIndexOf('@'));
      range.setStart(this.currentNode.node, start);
      range.setEnd(this.currentNode.node, end);
      range.deleteContents();

      range.insertNode(document.createTextNode("\u00A0"));
      var span = document.createElement("span");
      span.innerText = "@" + user.name;
      span.classList.add('mention-tags');
      span.contentEditable = 'false';
      span.setAttribute('data-id', user._id);
      span.setAttribute('data-id-ref', idref);
      range.insertNode(span);


      var sel = window.getSelection();
      // node.childNodes.item()

      // let temp = "";
      // for (var i = 0; i < node.childNodes.length; i++) {

      // }
      let indexForCursor = this.getChildNodeIndex(node.querySelector(`[data-id="${user._id}"][data-id-ref="${idref}"]`));


      sel.collapse(node, indexForCursor + 3);
      this.triggerPosition = -1;
      this.showList = false;

      let cursorPos = range.getClientRects()[0];
      let parentPos = node.getClientRects()[0];
      if (cursorPos.bottom > parentPos.bottom) {

        node.scrollTo(0, cursorPos.bottom - parentPos.top)
      }
    }, 2);


  }

  getChildNodeIndex(childNode: HTMLElement): number {
    const parent = this.editor.nativeElement as HTMLElement;
    const childNodes = parent.childNodes;
    let index = -1;
    for (var i = 0; i < childNodes.length; i++) {
      if (childNodes[i] == childNode) {
        index = i;
        break;
      }
    }
    return index;
  }

  formatComment() {   ///final output in formatted form

    this.triggerPosition = -1;
    this.showList = false;

    this.textInput = this.textInput.replace(/<div>/gi, "").replace(/<\/div>/gi, "");
    this.textInputChanged.emit(this.textInput);
    setTimeout(() => {
      const parent = this.editor.nativeElement as HTMLElement;
      
      let output = [];
      parent.childNodes.forEach((ele: HTMLElement) => {

        if (ele.nodeType == Node.ELEMENT_NODE && ele.nodeName == "SPAN") {
          output.push({
            type: 'mention', content: (ele.textContent || ele.innerText), id: ele.getAttribute('data-id')
          });
        }
        else if (ele.nodeType == Node.TEXT_NODE && ele.textContent != "") {

          ele.nodeValue = ele.nodeValue.replace(/\u00a0/g, " ");

          output.push({
            type: 'text', content: ele.textContent
          });
        }
      });

      if (output[output.length - 1].type == "text" && output[output.length - 1].content.trim() == "")
        output.pop();


      if (output.length > 0)
        this.formattedComment.emit(output);
    }, 2);

  }

  editorClicked() {
    var sel = window.getSelection();
    const range = sel.getRangeAt(0);
    this.currentNode = {
      node: range.startContainer,
      offset: sel.anchorOffset,
      srchTxtLength: 0
    };
    this.triggerPosition = -1;
    this.showList = false;
  }

  onPaste(pastedText) {

    if (pastedText == '@') {
      const cursorPosition = this.getCursorPosition();
      this.triggerPosition = cursorPosition;
      this.showList = true;
    }

    this.editor.nativeElement.scrollTo(0, this.editor.nativeElement.scrollHeight);
  }

  searchUser() {

    if (this.showList && this.triggerPosition > -1) {
      const cursorPosition = this.getCursorPosition();
      let text = this.convertToPlain(this.textInput);
      let searchTxt = text.substring(this.triggerPosition + 1, cursorPosition);

      if (searchTxt.trim() == "" && text.substring(this.triggerPosition, cursorPosition) != "@") {
        this.currentNode = {
          node: null,
          offset: -1,
          srchTxtLength: 0
        };
        this.triggerPosition = -1;
        this.showList = false;
        return;
      }

      var sel = window.getSelection();

      const range = sel.getRangeAt(0);

      if (range.startContainer != this.editor.nativeElement) {
        this.currentNode = {
          node: range.startContainer,
          offset: sel.anchorOffset,
          // srchTxtLength: endIndex == -1 ? searchTxt.length + 1 : searchTxt.length
          srchTxtLength: (cursorPosition - this.triggerPosition)
        };


        let cursorCoordinates = range.getClientRects()[0] as DOMRect;
        let parentCoordinates = this.editorParent.nativeElement.getClientRects()[0] as DOMRect;
        if (cursorCoordinates != undefined) {

          let xAxis = cursorCoordinates.x - parentCoordinates.x;
          let yAxis = parentCoordinates.bottom - cursorCoordinates.top; ///cursorCoordinates.bottom - cursorCoordinates.y;

          if (this.userList) {

            if ((xAxis + 300 - 25) > (parentCoordinates.width + 36)) {
              this.renderer.removeStyle(this.userList.nativeElement, "left");
              this.renderer.setStyle(this.userList.nativeElement, "right", "49px");
            }
            else {
              this.renderer.removeStyle(this.userList.nativeElement, "right");
              this.renderer.setStyle(this.userList.nativeElement, "left", xAxis + 'px');
            }
            this.renderer.setStyle(this.userList.nativeElement, "bottom", (yAxis + 10) + 'px');
          }
        }
        setTimeout(() => {

          this.userSearchObsv = of(searchTxt.toString()).pipe(
            map(value => (typeof value === 'string' ? value : '')),
            map(name => (name ? this._filter(name) : this.userData.slice())),
          );
        }, 1);
      }
      else {
        this.currentNode = {
          node: null,
          offset: -1,
          srchTxtLength: 0
        };
        this.triggerPosition = -1;
        this.showList = false;
      }
    }
  }

  private _filter(name: string): any {
    let filterValue = name.toLowerCase();
    filterValue = filterValue.replace(/\u00a0/g, " ");

    return this.userData.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  inputChanged() {

    if (this.keyNotHandled) {
      const cursorPosition = this.getCursorPosition();
      const value = this.convertToPlain(this.textInput);
      let typedCharacter = value.substring(cursorPosition - 1, cursorPosition);

      if (this.triggerPosition >= 0) {

        let triggerVal = value.substring(this.triggerPosition, cursorPosition);

        if (triggerVal && triggerVal.match(new RegExp(/^[@].*$/)))
          this.showList = true;
        else
          this.showList = false;
      }

      if (typedCharacter == '@') {
        let trigger = (value.substring(cursorPosition - 2, cursorPosition - 1) + '@').trimLeft();

        if (trigger && trigger.match(new RegExp(/^[@].*$/))) {
          this.triggerPosition = cursorPosition - 1;
          this.showList = true;
          this.searchUser();
        }
      }

    }
    this.textInputChanged.emit(this.textInput);
  }

}
export interface MentionUsers {
  _id: string;
  name: string;
  img: string;
}
