import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth.service'
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private AuthService: AuthService,
    private myRouter: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError(err => {
      let url = this.myRouter.routerState.snapshot.url;
      

      if (err.status === 401) {
        // // auto logout if 401 response returned from api
        // //  location.reload(true);

        // this.AuthService.logOut();

        localStorage.removeItem('barereToken');
        this.AuthService.resetUser();
        var OneSignal = window['OneSignal'] || [];

        OneSignal.push(function () {
          OneSignal.deleteTags(["name", "_id", "active"]);

        });

        sessionStorage.clear();

        if (url.includes('admin-login') || url.includes('subtraid-employee') || url.includes('isSubtraid=true'))
          this.myRouter.navigate(['admin-login']);
        else
          this.myRouter.navigate(['login']);
      }

      if (err.status === 404 && !err.url.includes('media')) {
        
        if (url.includes('admin-login') || url.includes('subtraid-employee') || url.includes('isSubtraid=true'))
          this.myRouter.navigate(['admin-login']);
        else
          this.myRouter.navigate(['dashboard', '404']);
      }

      const error = err.error || err.statusText;
      return throwError(error);
    }))
  }
}
