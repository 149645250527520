import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sum'
})
export class SumPipe implements PipeTransform {

  transform(item: any[], key?: string): number {
    return item.reduce((a, b) => a + b[key], 0);
  }

}
