import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectsService } from 'app/main/services/projects.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DeleteComponent>,
    private projectsService: ProjectsService,
    private router: Router
  ) { }
  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteProject() {
    this.projectsService.deleteProject(localStorage.getItem("project_id")).subscribe(res => {
      
      this.dialogRef.close();
      this.router.navigateByUrl("/dashboard/projects/boards");
    })
  }

}
