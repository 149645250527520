import { Component, EventEmitter, Input, OnInit, Output, HostListener } from '@angular/core';

@Component({
  selector: 'app-auto-complete-infinite-scroll',
  templateUrl: './auto-complete-infinite-scroll.component.html',
  styleUrls: ['./auto-complete-infinite-scroll.component.scss']
})
export class AutoCompleteInfiniteScrollComponent implements OnInit {

  @Input() listData = [];
  @Input() fieldName: string = '';
  @Input() loading: boolean = false;
  @Output() itemSelected = new EventEmitter();
  @Output() scrolledToBottom = new EventEmitter();
  @Input() arrowKeyLoc = 0;


  constructor() { }

  ngOnInit() {

  }

  selectItem(selected) {

    this.itemSelected.emit(selected);
  }

  @HostListener('scroll', ['$event'])
  onScroll(event) {

    var ele = event.target;

    if (Math.round(ele.scrollHeight - ele.scrollTop - ele.clientHeight) <= 3 && !this.loading)
      this.scrolledToBottom.emit();
  }
}
