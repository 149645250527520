import { Component, OnChanges, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediastorageService } from '../mediastorage.service'
import { environment } from '../../../../environments/environment.uat';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatMenuTrigger } from '@angular/material';
import { ImagePopupComponent } from '../image-popup/image-popup.component'


@Component({
  selector: 'secured-image',
  templateUrl: './secured-image.component.html',
  styleUrls: ['./secured-image.component.scss'],
  inputs: ['classes', 'imgType']
})
export class SecuredImageComponent implements OnChanges {
  // This code block just creates an rxjs stream from the src
  // this makes sure that we can handle source changes
  // or even when the component gets destroyed
  // So basically turn src into src$
  @Input() private src;
  @Input() classes: [];
  @Input() private hasPopup: boolean = false;
  @Input() mediaObject;

  basePath = environment.basePath;

  private src$;
  public popUpDialogRef: MatDialogRef<ImagePopupComponent>;
  public loading = true;
  public dataUrl$;

  ngOnChanges(): void {
    this.src$ = new BehaviorSubject(this.src);
    this.dataUrl$ = this.loadImage(this.src);
  }

  //dataUrl$ = '/assets/images/invisible.png';

  // this stream will contain the actual url that our img tag will load
  // everytime the src changes, the previous call would be canceled and the
  // new resource would be loaded


  // we need HttpClient to load the image
  constructor(
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
    private mediastorageService: MediastorageService,
    private _matDialog: MatDialog) {
  }

  private loadImage(url: string) {


    if (this.isDataURL(url)) {
      this.loading = false
      return new Promise(function (resolve, reject) {
        resolve(url);
      });
    }
    
    if (url === "/assets/images/invisible.png" || typeof url === 'undefined' || url === null || url == '5df149f51c9d44000010a809') {
      this.loading = false
      return new Promise(function (resolve, reject) {
        
        resolve("/assets/images/profile/957bd1b1-71fa-4bb8-9bae-e2fd12cef360.png");
      });
    }

    if (url === 'assets/images/no-image-available.jpg') {
      this.loading = false
      return new Promise(function (resolve, reject) {
        resolve('assets/images/no-image-available.jpg');
      });
    }

    if (url === 'assets/images/loading.gif') {
      this.loading = false
      return new Promise(function (resolve, reject) {
        resolve('assets/images/loading.gif');
      });
    }


    if (this.mediastorageService.getByID(url) !== null) {

      let sanitizeUrl = this.domSanitizer.bypassSecurityTrustUrl(this.mediastorageService.getByID(url));

      this.loading = false
      return new Promise(function (resolve, reject) {
        resolve(sanitizeUrl);
      });
    }

    return this.httpClient
      // load the image as a blob
      .get(this.basePath + '/media/' + url, { responseType: 'blob' })
      // create an object url of that blob that we can use in the src attribute
      .pipe(map(e => {

        let sanitizeUrl = URL.createObjectURL(e)
        this.mediastorageService.add(url, sanitizeUrl);
        return this.domSanitizer.bypassSecurityTrustUrl(sanitizeUrl)

      }, error => {

        return 'assets/images/no-image-available.jpg';

      }));
  }

  fallback(event: any) {

    if (this.loading) {

      event.target.src = 'assets/images/loading.gif';

    } else {

      event.target.src = 'assets/images/no-image-available.jpg';

    }

  }


  isDataURL(s) {

    // is the string being passed in are falsy just return  the string back
    if (!s) {
      return s
    }

    return !!s.match(/^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i);
  }

  showPopup() {

    if (!this.hasPopup) return;

    this.popUpDialogRef = this._matDialog.open(ImagePopupComponent, {
      data: {
        url: this.src,
        mediaObject: this.mediaObject
      }
    });
  }

}
