import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-subtraid-delete-dialog',
  templateUrl: './subtraid-delete-dialog.component.html',
  styleUrls: ['./subtraid-delete-dialog.component.scss']
})
export class SubtraidDeleteDialogComponent implements OnInit {

  message: string;

  constructor(
    public matDialogRef: MatDialogRef<SubtraidDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message = this.data;
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.matDialogRef.close();
  }

  close(): void {
    this.matDialogRef.close({ action: true });
  }

}
