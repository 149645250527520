import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'detectUrl'
})
export class DetectUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(value: any): any {
    if (!value) return value;

    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    const newVal = value.replace(urlRegex, function (url) {

      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    });
    return this.sanitizer.sanitize(SecurityContext.HTML, newVal);
  }

}
