import { Injectable } from "@angular/core";
import { environment } from "environments/environment.uat";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Card } from "../pages/dashboard/scrumboard/card";
import { ProjectPermissionSettings } from "../pages/dashboard/scrumboard/permissions";
import { Project } from "../pages/dashboard/scrumboard/project.model";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class ProjectsService {
    apiUrl = environment.basePath + "/projects";
    userProjectApiUrl = environment.basePath + "/users/projects";
    userTemplatesApiUrl = environment.basePath + "/templates";
    timesheetApiUrl = environment.basePath + "/timesheets";
    issueApiUrl = environment.basePath + "/projectIssues";
    private onProjectChange = new BehaviorSubject<Project | null>(null);
    public projectBoard = this.onProjectChange.asObservable();
    private onCardDetailUpdate = new BehaviorSubject<Card | null>(null);
    public projectCard = this.onCardDetailUpdate.asObservable();
    private onProjectSettingChange = new BehaviorSubject<ProjectPermissionSettings | null>(null);
    public projectSetting = this.onProjectSettingChange.asObservable();
    private onWeatherLocationChange = new BehaviorSubject(null);
    public weatherLocation = this.onWeatherLocationChange.asObservable();
    private onCollabProjChange = new BehaviorSubject(null);
    public collabProjects = this.onCollabProjChange.asObservable();
    private onProjDetailTourStart = new BehaviorSubject(null);
    public projDetailTourStarted = this.onProjDetailTourStart.asObservable();
    private onTargetDime = new BehaviorSubject(null);
    public targetDimension = this.onTargetDime.asObservable();
    pageSize: number = 30;
    height$ = new Subject<number>();

    stakeHolderRoles = ["Architect", "Engineer", "Architect / Engineer", "Owner", "Owner Representative"];

    setHeight(height: number) {
        this.height$.next(height);
    }

    getAll() {
        return this.baseCaller.get(this.apiUrl, true);
        // return this.baseCaller.get(environment.basePath+"/users/projects-list", true);
    }
    create(project) {
        return this.baseCaller.post(this.apiUrl, true, project);
    }
    getProject(byId, msg) {

        return this.baseCaller.get(this.apiUrl + "/" + byId, true);
    }
    deleteProject(id) {
        return this.baseCaller.delete(this.apiUrl + "/" + id, true, id);
    }
    getProjectsForUser() {
        return this.baseCaller.get(this.userProjectApiUrl + "/", true);
    }
    getProjectsOfEmployer() {
        return this.baseCaller.get(this.apiUrl + "/employer", true);
    }
    constructor(
        private baseCaller: BaseService,
        private _httpClient: HttpClient,

    ) { }


    //a5e

    // getUsersProject(sortByName: boolean) {
    //     let api = "/users/projects-list";
    //     if (sortByName)
    //         api = api + "?sort=name";
    //     return this.baseCaller.get(environment.basePath + api, true);
    // }

    getAllProjects(sortByName: boolean, pageNo: number, searchString) {
        let api = "/users/projects-list?";
        if (sortByName)
            api = api + "sort=name&";
        return this.baseCaller.get(environment.basePath + api + `pageNumber=${pageNo}&pageSize=${this.pageSize}&name=${searchString}`, true,);
    }

    getTemplates() {
        return this.baseCaller.get(this.userTemplatesApiUrl, true);
    }
    deleteTemplate(id) {
        return this.baseCaller.delete(this.userTemplatesApiUrl + "/" + id, true);
    }

    createTemplate(data) {
        return this.baseCaller.post(this.userTemplatesApiUrl, true, data);
    }

    addList(data) {
        return this.baseCaller.patch(this.apiUrl + '/list/add', true, data);
    }

    refreshProject(res) {
        let proj: Project;


        proj = {
            _id: res._id,
            lists: res.lists,
            author: res.author,
            owner: res.owner,
            dateCreated: res.dateCreated,
            startDate: res.startDate,
            formattedAddress: res.formattedAddress,
            location: res.location ? res.location : null,
            subprojects: res.subprojects,
            timesheets: res.timesheets,
            status: res.status,
            idMembers: res.idMembers,
            managers: res.managers,
            name: res.name,
            labels: res.labels,
            settings: res.settings,
            cards: res.cards,
            conversation: res.conversation ? res.conversation : '',
            collaborators: res.collaborators,
            loginUserProjectPermission: res.loginUserProjectPermission,
            idCrews: res.idCrews,
            isCollaborated: res.isCollaborated,
            permissions: res.permissions,
            isDateChangeApproved: res.isDateChangeApproved ? res.isDateChangeApproved : false,            // miscPermission: miscPermission
            endDate: res.endDate,
            isUpdateRequestPending: res.isUpdateRequestPending,
            GroupedIdMembers: res.GroupedIdMembers,
            SubGroupsIdMembers: res.SubGroupsIdMembers,
            finances: res.finances,
            stakeHolder: res.stakeHolder
        }
        if (res.parent) proj.parentProject = res.parent;
        if (res.value) proj.projectValue = res.value;
        if (proj.isDateChangeApproved)
            proj.approvalDays = res.approvalDays;

        proj.lists.forEach(list => {
            list.availableCards = [];
            proj.cards.forEach(card => {
                if (card.listId == list._id) {
                    list.availableCards.push(card);
                }
            });
        });

        proj.lists.forEach(list => {
            // list.availableLabels=[];
            list.availableCards.forEach(card => {
                card.availableLabels = [];
                card.idLabels.forEach(id => {
                    proj.labels.forEach(label => {
                        if (label._id == id)
                            card.availableLabels.push(label);
                    });
                });

            });

        });

        proj.lists.forEach((list) => {
            list.availableProjects = [];
            // list.idProjects.forEach(obj => {
            proj.subprojects.forEach(sub => {
                if (sub.parentCard.length > 0 && sub.parentCard[0].listId == list._id)
                    list.availableProjects.push(sub);
            });
            // });
        });

        proj.lists.sort((a, b) => a.position > b.position ? 1 : -1);

        this.onProjectChange.next(proj);

        let setting: ProjectPermissionSettings = {
            projectId: proj._id,
            loginUserProjectPermission: proj.loginUserProjectPermission,
            settings: proj.settings,
            isCollaboratedProject: proj.isCollaborated,
            status: proj.status
        };
        this.onProjectSettingChange.next(setting);


        //colaborated projects
        let collab = [];
        proj.collaborators.forEach((el) => {
            var cp = proj.subprojects.filter((obj) => {
                if (obj.owner._id == el._id)
                    return obj;
            });
            if (typeof cp !== 'undefined' && cp.length > 0)
                cp.forEach(data => collab.push(data));

        });

        this.onCollabProjChange.next(collab);
    }

    removeList(data) {
        return this.baseCaller.patch(this.apiUrl + '/list/remove', true, data);
    }

    addCard(data) {

        return this.baseCaller.patch(this.apiUrl + '/card/add', true, data);
    }

    addSubProject(data) {
        //'/projects/subproject/add';
        return this.baseCaller.patch(this.apiUrl + '/subproject/add', true, data);
    }

    shiftCard(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/shiftcard', true, data);
    }

    shiftSubProject(data) {
        return this.baseCaller.patch(this.apiUrl + '/subproject/shift', true, data);
    }

    changeListPosition(data) {
        return this.baseCaller.patch(this.apiUrl + '/list/reposition', true, data);
    }

    updateListName(data) {
        return this.baseCaller.patch(this.apiUrl + '/list/updatename', true, data);
    }

    searchInProjectByID(data, id) {
        return this.baseCaller.get(this.apiUrl + '/search/' + id, true, data);
    }

    getCardDetailByID(id, data) {
        return this.baseCaller.get(this.apiUrl + '/card/' + id, true, data);
    }

    updateCardStatic(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/update_card_static', true, data);
    }
    deleteCard(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/remove', true, data);
    }

    updateCardStatus(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/status', true, data);
    }

    createSubTask(data) {
        //task
        return this.baseCaller.patch(this.apiUrl + '/card/add/checklist', true, data);
    }

    createCheckListItem(data) {
        //create sub-task
        return this.baseCaller.patch(this.apiUrl + '/card/add/checkitem', true, data);
    }

    removeSubTask(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/remove/checklist', true, data);
    }

    updateSubTask(data) {
        //task
        return this.baseCaller.patch(this.apiUrl + '/card/update/checkList', true, data);
    }

    updateCheckList(data) {
        //sub-task
        return this.baseCaller.patch(this.apiUrl + '/card/update/checkItem', true, data);
    }

    modifyCardData(res: Card, projectId) {
        this.onCardDetailUpdate.next(res);

    }

    updateCheckListItem(data) {
        //subtask check-uncheck
        return this.baseCaller.patch(this.apiUrl + '/card/updateListChecked', true, data);
    }

    addComment(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/add/comment', true, data);
    }

    removeAttachment(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/attachment/delete', true, data);
    }

    addAttachment(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/attachment/add', true, data);
    }

    getCardAggregatedTimeSheet(data, cardid) {
        return this.baseCaller.post(environment.basePath + '/timesheets/filter/cards/aggregate-time/' + cardid, true, data);
    }

    startCardTimeSheet(data) {
        // const endPoint: string = this.basePath + '/timesheets/card';
        return this.baseCaller.post(environment.basePath + '/timesheets/card', true, data);
    }

    stopTimeSheet(timesheetid, data) {
        return this.baseCaller.patch(environment.basePath + '/timesheets/' + timesheetid, true, data);
    }

    stopMultipleTimeSheet(data) {
        return this.baseCaller.patch(environment.basePath + '/timesheets/timesheets-update', true, data);
    }

    addEmployeeToCard(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/addemployee', true, data);
    }

    removeMemberFromCard(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/removeemployee', true, data);
    }

    removeCrewFromCard(data) {
        return this.baseCaller.patch(this.apiUrl + '/card/removecrew', true, data);
    }

    updateProjectLabel(data) {
        return this.baseCaller.patch(this.apiUrl + '/label/update', true, data);
    }

    addLabelToProject(data) {
        return this.baseCaller.patch(this.apiUrl + '/label/add', true, data);
    }

    removeLabelFromProject(data) {
        // return this.baseCaller.patch(this.apiUrl + '/label/add', true, data);
    }

    updateProjectInfo(data, id) {
        return this.baseCaller.patch(this.apiUrl + '/' + id, true, data);
    }

    startProjectTimeSheet(data) {
        return this.baseCaller.post(environment.basePath + '/timesheets/project', true, data);
    }

    getSubprojectsCardsForIssues(id) {
        return this.baseCaller.get(this.apiUrl + '/subproject-card-detail/' + id, true);
    }

    addIssue(data) {
        return this.baseCaller.post(this.issueApiUrl + '/create', true, data);
    }

    getIssueList(data, id) {
        return this.baseCaller.get(this.issueApiUrl + '/filter/' + id, true, data);
    }

    updateIssue(data, id) {
        return this.baseCaller.patch(this.issueApiUrl + '/' + id, true, data);
    }

    deleteIssue(id, data) {
        return this.baseCaller.delete(this.issueApiUrl + '/' + id, true, data);
    }

    addIssueComment(data, id) {
        return this.baseCaller.patch(this.issueApiUrl + '/add-comment/' + id, true, data);
    }

    duplicateBoard(data) {
        return this.baseCaller.post(this.apiUrl + '/duplicate', true, data);
    }

    getPermissionSettings() {
        //for company user only
        return this.baseCaller.get(environment.basePath + '/users/employer/ppg', true);
    }

    createUserRole(data) {
        return this.baseCaller.patch(environment.basePath + '/companies/project_group_permission/create', true, data);
    }

    updateUserRole(data) {
        return this.baseCaller.patch(environment.basePath + '/companies/project_group_permission/update', true, data);
    }

    deleteUserRole(id) {
        return this.baseCaller.delete(environment.basePath + '/companies/project_group_permission/' + id, true);
    }

    getProjectPermissionSettings(id) {
        return this.baseCaller.get(this.apiUrl + '/permissions-settings/' + id, true);
    }

    updateProjectTimesheetSetting(id, data) {
        return this.baseCaller.patch(this.apiUrl + '/settings/' + id, true, data);
    }

    refreshProjectSetting(res: ProjectPermissionSettings) {
        this.onProjectSettingChange.next(res);
    }

    getWeather(lng, lat) {
        const endPoint: string = environment.basePath + `/weather?lng=${lng}&lat=${lat}`;
        return this.baseCaller.get(endPoint, true);
    }

    updateWeatherLoc(loc: any) {

        if (loc) {
            if (loc.lat != null && loc.lng != null) {
                this.getWeather(loc.lng, loc.lat)
                    .subscribe(resp => {
                        this.onWeatherLocationChange.next(resp.weather);
                    });
            }
            else
                this.onWeatherLocationChange.next(null);
        }
        else
            this.onWeatherLocationChange.next(null);

    }

    getNonDependentCards(projectid, cardid, pageNo, getAll: boolean) {
        if (getAll)
            return this.baseCaller.get(this.apiUrl + `/${projectid}/cards?pageNumber=${pageNo}&pageSize=10`, true);
        else
            return this.baseCaller.get(this.apiUrl + `/${projectid}/cards?cardId=${cardid}&pageNumber=${pageNo}&pageSize=10`, true);
    }

    createCardDepedency(data) {
        return this.baseCaller.patch(this.apiUrl + '/cards/dependent-task', true, data);
    }

    getDependentCards(cardid) {
        return this.baseCaller.get(this.apiUrl + "/cards/" + cardid + "/dependent-task", true);
    }

    updateDepedencyNotes(data) {
        return this.baseCaller.patch(this.apiUrl + "/cards/dependent-task/notes", true, data);
    }

    deleteDepedency(cardid, dependentOnid) {
        return this.baseCaller.patch(this.apiUrl + "/cards/" + cardid + "/dependent-task/" + dependentOnid, true, dependentOnid);
    }

    getProjectCards(projectid, cardid) {
        return this.baseCaller.get(`${this.apiUrl}/${projectid}/lists?cardId=${cardid}`, true);
    }

    startProjectDetailTour(data: boolean) {
        this.onProjDetailTourStart.next(data);
    }

    updateTargetDimension(data) {
        this.onTargetDime.next(data);
    }

    updateMemberRole(data) {
        return this.baseCaller.patch(this.apiUrl + '/permission/update', true, data);
    }

    raiseIssueForDateChange(data: any) {
        return this.baseCaller.patch(this.apiUrl + '/card/propose_card_dates', true, data);
    }

    getDateChangeIssueById(issueid: string, projectid: string) {
        return this.baseCaller.get(this.issueApiUrl + '/date-change-issue/' + issueid + '?projectId=' + projectid, true);
    }

    saveDateChangeIssueAction(projectid: string, data: any) {
        return this.baseCaller.patch(this.issueApiUrl + '/date-change-issue/update-status?projectId=' + projectid, true, data);
    }

    getPendingIssues() {
        return this.baseCaller.get(environment.basePath + '/users/project-issues/date-change-pending-issues', true);
    }

    getAllArchivedProjects(data, pageNo) {
        return this.baseCaller.post(this.apiUrl + `/archive/projects/all?pageNumber=${pageNo}&pageSize=${this.pageSize}`, true, data);
    }

    restoreProject(projectid) {
        return this.baseCaller.post(this.apiUrl + '/archive/restore/' + projectid, true, null);
    }

    getSubProjectUpdateRequest() {
        return this.baseCaller.get(environment.basePath + "/users/requests?type=projectUpdate", true);
    }

    processInfoCR(accept, id) {
        return this.baseCaller.post(environment.basePath + '/users/accept-projectUpdate?requestId=' + id + '&accept=' + accept, true, null);
    }

    addBills(data, projectid) {
        return this.baseCaller.patch(this.apiUrl + '/finance/' + projectid, true, data);
    }


    getAuthorizedUserForPCR(projectid: string) {
        return this.baseCaller.get(environment.basePath + "/users/authorizer/" + projectid, true);
    }

    createPCR(data) {
        return this.baseCaller.post(this.apiUrl + '/create-pcr', true, data);
    }

    getIssuedPCR(projectid: string, status: string, substatus: string, srchKey: string) {
        return this.baseCaller.get(environment.basePath + `/users/issued-pcr/${projectid}?status=${status}&subStatus=${substatus}&search=${srchKey}`, true);
    }

    getReceivedPCR(projectid: string, status: string, substatus: string, srchKey: string) {
        return this.baseCaller.get(environment.basePath + `/users/received-pcr/${projectid}?status=${status}&subStatus=${substatus}&search=${srchKey}`, true);
    }

    updatePCR(data) {
        return this.baseCaller.patch(environment.basePath + "/users/update-pcr", true, data);
    }

    downloadPCRFile(mediaid) {
        return this._httpClient.get(environment.basePath + "/media/pcr/" + mediaid, { responseType: 'blob' });
    }

    uploadSignatureDocForPCR(data, pcrid) {
        return this.baseCaller.patch(environment.basePath + `/users/pcr-signature-upload/${pcrid}`, true, data);
    }

    getPCRDetailById(pcrid) {
        return this.baseCaller.get(environment.basePath + "/users/pcr/" + pcrid, true);
    }

    updateBillInfo(data, projectid) {
        return this.baseCaller.patch(this.apiUrl + `/finance/update/${projectid}`, true, data);
    }

    downloadUnsignedPCRDoc(pcrid, preview) {
        if (preview)
            return this._httpClient.get(environment.basePath + "/users/generate-pdf/" + pcrid + '?isSigned=true', { responseType: 'blob' });
        else
            return this._httpClient.get(environment.basePath + "/users/generate-pdf/" + pcrid, { responseType: 'blob' });

    }

    uploadSignatureForPCR(data, pcrid) {
        return this.baseCaller.patch(environment.basePath + `/users/pcr-sign-upload/${pcrid}`, true, data);
    }

    getPCRTermsNConditions() {
        return this.baseCaller.get(environment.basePath + '/users/terms/get', true)
    }

    getSubProjectMembersForRFI(subpId: string) {
        return this.baseCaller.get(environment.basePath + `/users/project/members/${subpId}`, true);
    }

    addRFI(data) {
        return this.baseCaller.post(environment.basePath + '/rfi/create', true, data);
    }

    getIssuedRFIs(projectid, currentPage, status: string, srchKey: string) {
        return this.baseCaller.get(environment.basePath + `/rfi/issued/${projectid}?pageNumber=${currentPage}&pageSize=${this.pageSize}&status=${status}&search=${srchKey}`, true);
    }

    getReceivedRFIs(projectid, currentPage, status: string, srchKey: string) {
        return this.baseCaller.get(environment.basePath + `/rfi/received/${projectid}?pageNumber=${currentPage}&pageSize=${this.pageSize}&status=${status}&search=${srchKey}`, true);
    }

    updateRFI(data, rfid: string) {
        return this.baseCaller.patch(environment.basePath + `/rfi/${rfid}`, true, data);
    }

    downloadRFIFile(mediaid) {
        return this._httpClient.get(environment.basePath + "/media/rfi/" + mediaid, { responseType: 'blob' });
    }

    addRFIComment(rfid, data) {
        return this.baseCaller.patch(environment.basePath + '/rfi/message/add/' + rfid, true, data);
    }

    convertHTMLToPlainText(html) {
        var tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    getFileExtensionRFI(fileType) {
        if (fileType.includes('image/bmp') || fileType.includes('image/gif') || fileType.includes('image/jpeg') || fileType.includes('image/tiff') || fileType.includes('image/png')) return 'image';
        else if (fileType.includes('text/plain') || fileType.includes('rtf') || fileType.includes('wordprocessingml') || fileType.includes('msword') || fileType.includes('opendocument.text')) return 'doc';
        else if (fileType.includes('pdf')) return 'pdf';
        else if (fileType.includes('ms-excel') || fileType.includes('spreadsheetml') || fileType.includes('spreadsheet') || fileType.includes('csv')) return 'xls';
        else if (fileType.includes('ms-powerpoint') || fileType.includes('presentation')) return 'gd-ppt';
        else if (fileType.includes('video')) return 'db-video';
        else return 'general';
    }

    getRFIMessages(rfid, currentPage: number, pageSize: number) {
        return this.baseCaller.get(environment.basePath + `/rfi/message/${rfid}?pageNumber=${currentPage}&pageSize=${pageSize}`, true);
    }

    setRFIStatus(rfid: string, data: any) {
        return this.baseCaller.patch(environment.basePath + '/rfi/status/' + rfid, true, data);
    }

    getRFIDetailById(rfiid) {
        return this.baseCaller.get(environment.basePath + `/rfi/${rfiid}`, true);
    }

    getClosedRFIs(projectid: string, currentPage: number, srckKey: string) {
        return this.baseCaller.get(environment.basePath + `/rfi/list/${projectid}?pageNumber=${currentPage}&pageSize=${this.pageSize}&search=${srckKey}`, true);
    }

    createNewStakeHolder(data, projectid) {
        return this.baseCaller.patch(this.apiUrl + '/stakeholder/add/' + projectid, true, data)
    }

    removeStakeholderFromProject(data) {
        return this.baseCaller.delete(this.apiUrl + '/stakeholder/remove', true, data);
    }

    updateStakeholderRoleInProject(projectid, data) {
        return this.baseCaller.patch(this.apiUrl + '/stakeholder/update/' + projectid, true, data);
    }

    getAllStakeholders(currentPage: number, srchString?: string) {
        return this.baseCaller.get(this.apiUrl + `/stakeholder/list?page=${currentPage}&limit=5&search=${srchString}`, true);
    }

    addExistingStakeholderInProject(projectid: string, data) {
        return this.baseCaller.patch(this.apiUrl + '/stakeholder/add/existing/' + projectid, true, data);
    }

    createSubmittal(data) {
        return this.baseCaller.post(environment.basePath + '/submittals/create', true, data);
    }

    getIssuedSubmittals(projectid, currentPage, srchKey: string) {
        return this.baseCaller.get(environment.basePath + `/submittals/issued/${projectid}?pageNumber=${currentPage}&pageSize=5&search=${srchKey}`, true);
    }

    getSubmittalDetailById(id: string) {
        return this.baseCaller.get(environment.basePath + '/submittals/' + id, true);
    }

    updateSubmittal(data) {
        return this.baseCaller.patch(environment.basePath + '/submittals/update', true, data);
    }

    getReceivedSubmittals(projectid, currentPage, srchKey: string) {
        return this.baseCaller.get(environment.basePath + `/submittals/received/${projectid}?pageNumber=${currentPage}&pageSize=5&search=${srchKey}`, true);
    }

    getSubmittalComments(id, currentPage: number) {
        return this.baseCaller.get(environment.basePath + `/submittals/comments/${id}?pageNumber=${currentPage}&pageSize=10`, true);
    }

    addSubmittalComment(data, id) {
        return this.baseCaller.patch(environment.basePath + '/submittals/add-comment/' + id, true, data);
    }

    downloadSubmittalFile(mediaId) {
        return this._httpClient.get(environment.basePath + "/media/submittal/" + mediaId, { responseType: 'blob' });
    }
    //a5e end
}
