import { Component, OnInit , Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatMenuTrigger } from '@angular/material';
import { MediastorageService } from '../mediastorage.service'

@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.scss']
})
export class ImagePopupComponent implements OnInit {

  dataUrl$ : any = "/assets/images/invisible.png";
  mediaObject;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private mediastorageService: MediastorageService,
    private domSanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
      this.dataUrl$ = this.loadImage(this._data.url);
      this.mediaObject = this._data.mediaObject;
  }

  private loadImage(url: string) {

    if(this.isDataURL(url)){
      return new Promise(function(resolve, reject){
        resolve(url);
      });
    }

    if (url === "/assets/images/invisible.png" || typeof url === 'undefined' ){

      return new Promise(function(resolve, reject){
        resolve("/assets/images/invisible.png");
      });
    }

    if(this.mediastorageService.getByID(url) !== null){

      let sanitizeUrl = this.domSanitizer.bypassSecurityTrustUrl(this.mediastorageService.getByID(url));

      return new Promise(function(resolve, reject){
        resolve(sanitizeUrl);
      });
    }

  }

  isDataURL(s) {

    // is the string being passed in are falsy just return  the string back
     if(!s){
      return s
     }

     return !!s.match(/^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i);
  }

}
